<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.word" placeholder="Word" />
      <input type="text" v-model="item.answer" placeholder="Answer" />
      <input type="text" v-model="item.emptyNumbers" placeholder="Empty numbers" />
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
  // "letterWords": [
  //   {
  //     "word": "learning",
  //     "answer": "I love learning english",
  //     "emptyNumbers": [
  //       1,
  //       2,
  //       6
  //     ]
  //   },
  // ]

export default {
  name: 'Type7c',
  data() {
    return {
      letterWords: [
        {
          word: '',
          answer: '',
          emptyNumbers: '',
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords))
      result = result.map(item => {
        item.emptyNumbers = item.emptyNumbers.replace(/\s/g, '').replace(/[\,\/]/gi, ',').split(',')
        item.emptyNumbers = item.emptyNumbers.map(v => +v)
        return item
      })
      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
        word: '',
        answer: '',
        emptyNumbers: '',
      });
    },
  },
};
</script>
